<template>
  <v-card style="padding: 15px; border-radius: 5px;">
    <v-card-title class="d-flex justify-center pb-8">
      Cadastrar {{ textType }}
    </v-card-title>

    <v-card-text style="display: flex; flex-direction: column;">
      <div class="separator-title">
        <p class="px-2">
          {{ separatorText }}
        </p>
      </div>

      <v-form
        ref="form"
        @submit.prevent="handleRequest()"
      >
        <v-text-field
          v-model="nameField"
          class="mt-6 mb-0"
          :label="inputLabel"
          :rules="[rulesTextFiled.required]"
          outlined
          dense
        />

        <v-file-input
          v-show="typeOfTab !== 'process'"
          v-model="files"
          :append-icon="icons.mdiPaperclip"
          prepend-icon=""
          label="Logo"
          class="mb-0"
          outlined
          :rules="[rules.required]"
          dense
        />
      </v-form>

      <v-switch
        v-if="typeOfTab === 'process'"
        v-model="activeProcess"
        class="ml-2"
        label="Ativo"
        color="success"
        inset
      />

      <div
        class="d-flex justify-end"
        style="gap: 25px;"
      >
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          :loading="isLoadingRequest"
          @click="handleRequest()"
        >
          Salvar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiPaperclip } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    typeOfTab: {
      type: String,
      default: 'process',
    },
  },

  data() {
    const separatorText = {
      process: 'Novo Processo',
      company: 'Nova Empresa',
      occupation: 'Nova Função',
    }[this.typeOfTab]

    const inputLabel = {
      process: 'Nome do Processo',
      company: 'Nome da Empresa',
      occupation: 'Nome da Função',
    }[this.typeOfTab]

    const textType = {
      process: 'Processo',
      company: 'Empresa',
      occupation: 'Função',
    }[this.typeOfTab]

    return {
      file: '',
      icons: {
        mdiPaperclip,
      },
      nameField: ' ',
      activeProcess: false,
      isLoadingRequest: false,

      textType,
      inputLabel,
      separatorText,
      idEnterprise: '',

      rulesTextFiled: { required: value => (!!value?.trim()) || 'Este campo é obrigatório.' },
    }
  },

  watch: {
    nameField(newValue) {
      this.nameField = newValue?.toUpperCase().trim()
    },
  },

  methods: {
    async uploadImage() {
      try {
        const formData = new FormData()

        formData.append('id', this.idEnterprise)
        formData.append('files[]', this.files)

        await axiosIns.post('/api/v1/sgq/company/profile_picture', formData)
      } catch (error) {
        this.showMessage({
          title: 'Erro',
          text: 'Erro ao salvar imagem!',
          color: 'error',
        })
        console.error(error)
      }
    },

    async sendRequest(endpoint, body) {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) return

      try {
        this.isLoadingRequest = true

        const response = await axiosIns.post(endpoint, body)

        this.idEnterprise = response.data.data.id

        if (this.files) await this.uploadImage()

        this.$emit('updatedTable')

        this.showMessage({ title: 'Sucesso', text: 'Dados enviados com sucesso', icon: 'success' })
      } catch (error) {
        console.error(error)
        this.showErrorMessage(error)
      } finally {
        this.isLoadingRequest = false
        this.resetForm()
        this.closeModal()
      }
    },

    getEndpoint() {
      const prefix = 'api/v1/sgq'

      return `${prefix}/${this.typeOfTab}/store`
    },

    handleRequest() {
      const endpoint = this.getEndpoint()

      const body = this.typeOfTab === 'process' ? {
        description: this.nameField,
        status: this.activeProcess ? 'ATIVO' : 'INATIVO',
      } : { name: this.nameField }

      this.sendRequest(endpoint, body)
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>

 <style lang="scss">
 .separator-title {
  border-bottom: 1px solid #CAC9CD;
  height: 1px;
  width: 100%;
  margin-bottom: 10px;

  p {
    position: relative;
    bottom: 10px;
    left: 40px;
    background: #312D4B;
    width: fit-content;
    padding-right: 5px;
    padding-left: 5px;
  }
}

 </style>
