<template>
  <v-tabs
    v-model="activeTab"
    style="border-radius: 5px"
  >
    <v-tab>
      <div style="display: flex; align-items: center; gap: 5px">
        <v-icon>{{ icons.mdiFileCogOutline }}</v-icon>
        <span>Processos</span>
      </div>
    </v-tab>

    <v-tab-item>
      <div class="mt-4">
        <Process
          :processes="processes"
          :filtered-processes="filteredProcesses"
          :is-loading-processes="isLoadingProcesses"
          @updatedTable="getAllData()"
        />
      </div>
    </v-tab-item>

    <v-tab>
      <div style="display: flex; align-items: center; gap: 5px">
        <v-icon>{{ icons.mdiHomeCity }}</v-icon>
        <span>Empresa</span>
      </div>
    </v-tab>

    <v-tab-item>
      <div class="mt-4">
        <Company
          :companies="companies"
          :filtered-companies="filteredCompanies"
          :is-loading-companies="isLoadingCompanies"
          @updatedTable="getAllData()"
        />
      </div>
    </v-tab-item>

    <!-- NOTE: the tab is temporally hidden -->
    <v-tab v-if="false">
      <div style="display: flex; align-items: center; gap: 5px">
        <v-icon>{{ icons.mdiAccountOutline }}</v-icon>
        <span>Funções</span>
      </div>
    </v-tab>

    <v-tab-item>
      <div class="mt-4">
        <Occupation
          :occupations="occupations"
          :filtered-occupations="filteredOccupations"
          :is-loading-occupations="isLoadingOccupations"
          @updatedTable="getAllData()"
        />
      </div>
    </v-tab-item>

    <v-spacer />

    <div
      class="d-flex px-4 "
      style="padding: 10px; gap: 20px;"
    >
      <v-text-field
        v-model="searchTerm"
        :prepend-inner-icon="icons.mdiMagnify"
        style="margin-top: -2px;"
        :label="`Buscar ${getActiveTabText()}`"
        outlined
        dense
      />

      <v-btn
        style="margin-top: -1px;"
        color="info"
        outlined
        @click="openModalCreate()"
      >
        <v-icon
          size="25"
          class="mr-2"
        >
          {{ icons.mdiPlaylistPlus }}
        </v-icon>

        <span> Novo </span>
      </v-btn>
    </div>

    <v-dialog
      v-model="showModalCreate"
      width="700px"
    >
      <ModalCreate
        :key="activeTab"
        :type-of-tab="getTypeOfTab()"
        @updatedTable="getAllData()"
        @close="showModalCreate = false"
      />
    </v-dialog>
  </v-tabs>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiFileCogOutline,
  mdiHomeCity,
  mdiMagnify,
  mdiPlaylistPlus,
} from '@mdi/js'

import Company from './tabs/Company.vue'
import Occupation from './tabs/Occupation.vue'
import Process from './tabs/Process.vue'

import ModalCreate from './ModalCreate.vue'

export default {
  components: {
    Process,
    Company,
    Occupation,
    ModalCreate,
  },

  data() {
    return {
      activeTab: null,

      searchTerm: '',
      processes: [],
      filteredProcesses: [],

      occupations: [],
      filteredOccupations: [],

      companies: [],
      filteredCompanies: [],

      showModalCreate: false,
      showModalEdit: false,

      isLoadingProcesses: false,
      isLoadingCompanies: false,
      isLoadingOccupations: false,

      icons: {
        mdiPlaylistPlus,
        mdiFileCogOutline,
        mdiAccountOutline,
        mdiMagnify,
        mdiHomeCity,
      },
    }
  },

  watch: {
    searchTerm() {
      this.findRegister()
    },
  },

  created() {
    this.getAllData()
  },

  methods: {
    async getAllData() {
      this.searchTerm = ''

      const [processes, occupations, companies] = await Promise.all([this.getProcesses(), this.getOccupations(), this.getCompanies()])

      this.processes = processes
      this.filteredProcesses = processes

      this.occupations = occupations
      this.filteredOccupations = occupations

      this.companies = companies
      this.filteredCompanies = companies
    },

    async getProcesses() {
      this.isLoadingProcesses = true

      try {
        const response = await axiosIns.get('api/v1/sgq/process/index')

        return response.data.data
      } catch (error) {
        console.error(error)
        this.showErrorMessage(error)

        return []
      } finally {
        this.isLoadingProcesses = false
      }
    },

    async getCompanies() {
      this.isLoadingCompanies = true

      try {
        const response = await axiosIns.get('api/v1/sgq/company/index')

        return response.data.data
      } catch (error) {
        console.error(error)
        this.showErrorMessage(error)

        return []
      } finally {
        this.isLoadingCompanies = false
      }
    },

    async getOccupations() {
      this.isLoadingOccupations = true

      try {
        const response = await axiosIns.get('api/v1/sgq/occupation/index')

        return response.data.data
      } catch (error) {
        console.error(error)
        this.showErrorMessage(error)

        return []
      } finally {
        this.isLoadingOccupations = false
      }
    },

    getActiveTabText() {
      const tabMap = {
        0: 'Processo',
        1: 'Empresa',
        2: 'Função',
      }

      return tabMap[this.activeTab]
    },

    getTypeOfTab() {
      const tabMap = {
        0: 'process',
        1: 'company',
        2: 'occupation',
      }

      return tabMap[this.activeTab]
    },

    findRegister() {
      const searchTerm = this.searchTerm.toLowerCase()

      if (this.activeTab === 0) {
        this.filteredProcesses = this.processes.filter(process => process?.description.toLowerCase()
          .includes(searchTerm))
      }

      if (this.activeTab === 1) {
        this.filteredCompanies = this.companies.filter(company => company?.name.toLowerCase()
          .includes(searchTerm))
      }

      if (this.activeTab === 2) {
        this.filteredOccupations = this.occupations.filter(occupation => occupation?.name.toLowerCase()
          .includes(searchTerm))
      }
    },

    openModalCreate() {
      this.showModalCreate = true
    },

    openModalEdit() {
      this.showModalEdit = true
    },
  },
}
</script>
