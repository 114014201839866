<template>
  <v-card style="padding: 15px; border-radius: 5px;">
    <v-card-title class="d-flex justify-center pb-8">
      Editar {{ textType }}
    </v-card-title>

    <v-card-text style="display: flex; flex-direction: column;">
      <div class="separator-title">
        <p class="px-2">
          Editar {{ textType }}
        </p>
      </div>

      <v-form
        ref="form"
        @submit.prevent="handleRequest()"
      >
        <v-text-field
          v-model="nameField"
          class="mt-6 mb-0"
          :label="inputLabel"
          :rules="[rulesTextFiled.required]"
          outlined
          dense
        />

        <v-file-input
          v-show="typeOfTab !== 'process'"
          v-model="archive"
          :append-icon="icons.mdiPaperclip"
          prepend-icon=""
          label="Logo"
          class="mb-0"
          outlined
          :rules="[rules.required]"
          dense
        />
      </v-form>

      <v-switch
        v-if="typeOfTab === 'process'"
        v-model="activeProcess"
        class="ml-2"
        label="Ativo"
        color="success"
        inset
      />

      <div
        class="d-flex justify-end"
        style="gap: 25px;"
      >
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          :loading="isLoadingRequest"
          @click="handleRequest()"
        >
          Salvar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiPaperclip } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    data: {
      type: Object,
      default: () => {},
    },

    typeOfTab: {
      type: String,
      default: '',
    },
  },

  data() {
    const isProcessTab = this.typeOfTab === 'process'
    console.log('modal edit: ', this.data)

    const nameField = isProcessTab ? this.data?.description : this.data?.name
    const archive = this.data?.url_photo

    const inputLabel = {
      process: 'Nome do Processo',
      company: 'Nome da Empresa',
      occupation: 'Nome da Função',
    }[this.typeOfTab]

    const textType = {
      process: 'Processo',
      company: 'Empresa',
      occupation: 'Função',
    }[this.typeOfTab]

    return {
      activeProcess: false,
      isLoadingRequest: false,

      icons: {
        mdiPaperclip,
      },

      textType,
      nameField,
      archive,
      inputLabel,
      rulesTextFiled: { required: value => (!!value?.trim()) || 'Este campo é obrigatório.' },
    }
  },

  watch: {
    nameField(newValue) {
      this.nameField = newValue?.toUpperCase().trim()
    },
    archive(newValue) {
      this.archive = newValue
    },
  },

  methods: {
    async uploadImage() {
      try {
        const formData = new FormData()

        formData.append('id', this.data.id)
        formData.append('files[]', this.archive)

        await axiosIns.post('/api/v1/sgq/company/profile_picture', formData)
      } catch (error) {
        this.showMessage({
          title: 'Erro',
          text: 'Erro ao salvar imagem!',
          color: 'error',
        })
        console.error(error)
      }
    },

    async sendRequest(endpoint, body, id) {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) return

      console.log({ endpoint, id, body })

      try {
        this.isLoadingRequest = true

        await axiosIns.put(`${endpoint}/${id}`, body)
        this.$emit('updatedTable')

        if (this.archive) await this.uploadImage()

        this.showMessage({ title: 'Sucesso', text: 'Dados atualizados com sucesso', icon: 'success' })
      } catch (error) {
        console.error(error)
        this.showErrorMessage(error)
      } finally {
        this.isLoadingRequest = false
        this.closeModal()
      }
    },

    getEndpoint() {
      const prefix = 'api/v1/sgq'

      return `${prefix}/${this.typeOfTab}/update`
    },

    handleRequest() {
      const endpoint = this.getEndpoint()

      const body = this.typeOfTab === 'process' ? {
        description: this.nameField,
        status: this.activeProcess ? 'ATIVO' : 'INATIVO',
      } : { name: this.nameField }

      this.sendRequest(endpoint, body, this.data.id)
    },
  },
}
</script>

 <style lang="scss">
 .separator-title {
  border-bottom: 1px solid #CAC9CD;
  height: 1px;
  width: 100%;
  margin-bottom: 10px;

  p {
    position: relative;
    bottom: 10px;
    left: 40px;
    background: #312D4B;
    width: fit-content;
    padding-right: 5px;
    padding-left: 5px;
  }
}
</style>
