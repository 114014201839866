<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredProcesses"
      :loading="isLoadingProcesses"
      loading-text="Carregando dados..."
    >
      <template
        v-slot:no-data
      >
        <span>Sem informações para exibir</span>
      </template>

      <template
        v-slot:item.created_at="{item}"
      >
        {{ dateFormat(item.created_at) }}
      </template>

      <template v-slot:item.edit="{item}">
        <div
          class="d-flex justify-center align-center"
          style="gap: 5px;"
        >
          <v-icon
            medium
            color="info"
            @click="openModalEdit(item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </div>
      </template>

      <template v-slot:item.delete="{item}">
        <div
          class="d-flex justify-center align-center"
          style="gap: 5px;"
        >
          <v-icon
            medium
            color="error"
            @click="deleteRegister(endpointToDeleteProcess, item.id)"
          >
            {{ icons.mdiTrashCan }}
          </v-icon>
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showModalEdit"
      width="700px"
    >
      <ModalEdit
        :key="process.id"
        :data="process"
        :type-of-tab="'process'"
        @updatedTable="$emit('updatedTable')"
        @close="showModalEdit = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil, mdiTrashCan } from '@mdi/js'
import ModalEdit from '../ModalEdit.vue'

export default {
  components: {
    ModalEdit,
  },

  mixins: [messages, formatters],

  props: {
    processes: {
      type: Array,
      default: () => [],
    },

    filteredProcesses: {
      type: Array,
      default: () => [],
    },

    isLoadingProcesses: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      process: {},
      endpointToDeleteProcess: 'api/v1/sgq/process/destroy',

      headers: [
        {
          text: 'DESCRIÇÃO', value: 'description', sortable: false, align: 'left',
        },
        {
          text: 'STATUS', value: 'status', sortable: false, align: 'center',
        },
        {
          text: 'CRIADO EM', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'EDITAR', value: 'edit', sortable: false, align: 'center',
        },
        {
          text: 'DELETAR', value: 'delete', sortable: false, align: 'center',
        },
      ],

      icons: { mdiTrashCan, mdiPencil },

      showModalEdit: false,
    }
  },

  methods: {
    openModalEdit(process = {}) {
      this.process = process
      this.showModalEdit = true
    },
  },
}
</script>
