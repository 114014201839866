import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredProcesses,"loading":_vm.isLoadingProcesses,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"gap":"5px"}},[_c(VIcon,{attrs:{"medium":"","color":"info"},on:{"click":function($event){return _vm.openModalEdit(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"gap":"5px"}},[_c(VIcon,{attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpointToDeleteProcess, item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}])}),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showModalEdit),callback:function ($$v) {_vm.showModalEdit=$$v},expression:"showModalEdit"}},[_c('ModalEdit',{key:_vm.process.id,attrs:{"data":_vm.process,"type-of-tab":'process'},on:{"updatedTable":function($event){return _vm.$emit('updatedTable')},"close":function($event){_vm.showModalEdit = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }